import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import ImgGrid from '../components/imgGrid';
import Seo from '../components/seo';

export default function GalleryPage({ data }) {
  const { wide, vert } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo
        title="Gallery of Past Projects"
        description="Explore our gallery of past projects, showcasing our knack for crafting one-of-a-kind, stylish, and functional interior spaces. Discover how we've turned dreams into reality, creating homes where comfort, beauty, and practicality harmoniously coexist."
      />
      <div className="page-template">
        <div className="flex flex-col">
          <ImgGrid data={wide} cols={3} />
          <div className="py-6" />
          <ImgGrid data={vert} cols={4} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { slug: { eq: "gallery" }, type: { eq: "gallery" } }
    ) {
      frontmatter {
        vert {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        wide {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
